<style scoped>
.home {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #3f51b5, #5369e3);
  font-family: serif;
}
.header {
  width: 100%;
  height: 80px;
  line-height: 80px;
  color: #fff;
  font-size: 23px;
  font-weight: 500;
  position: relative;
  display: flex;
  justify-content: center;
}
.header .back img {
  position: absolute;
  top: 28px;
  left: 20px;
  width: 16pt;
  height: 16pt;
}
/* .btn {
  background: red;
} */
.header .btn {
  position: absolute;
  top: 28px;
  right: 20px;
  line-height: 23px;
  font-size: 20px;
}
.middle {
  display: inline-block;
  width: 0px;
  height: 100%;
  vertical-align: middle;
}
.body {
  width: 90%;
  /* margin: 3vh 5% 0; */
  /* height: 77vh; */
  height: calc(100vh - 120px);
  margin: 0 auto;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(208, 35, 36, 0.05);
  overflow-y: scroll;
}

.title {
  /*超出部分隐藏*/
  overflow: hidden;
  /*强制在一行*/
  white-space: nowrap;
  /*文本超出部分省略*/
  text-overflow: ellipsis;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.hisback {
  position: fixed;
  z-index: 999;
  top: 0;
  padding: 0 20px;
  width: 100%;
  height: 3rem;
  /* background: var(--searchColor); */
  background: white;
  color: #aaa;
  line-height: 3rem;
  font-size: 1.2rem;
  box-shadow: 0px 3px 3px rgba(30, 155, 204, 0.05);
  display: flex;
}
.back_box {
  width: 100%;
  height: 3rem;
}

.center {
  display: inline-block;
  width: 0px;
  height: 100%;
  vertical-align: middle;
}
.splice {
  width: 100%;
  height: 10px;
  background: #f1f1f1;
  margin-top: 10px;
}
.problem_list_his {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 0;
}

.list_con .list_con_one {
  padding: 10px;
  padding-top: 15px;
  padding-left: 12px;
  margin: 4px 0;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  background: #fff;
  border-radius: 5px;
  box-shadow: 1px 2px 3px 4px rgba(30, 155, 204, 0.05);
}
</style>
<template>
  <div class="home">
    <div class="header">
      <div
        style="
          width: 100%;
          display: flex;
          align-items: center;
          position: relative;
        "
      >
        <div class="title" style="flex: 1">
          <svg
            t="1701162806656"
            class="icon"
            viewBox="0 0 2048 1024"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            p-id="7311"
            width="60"
            height="60"
          >
            <path
              d="M801.28 229.888H396.16L213.504 924.544H0L242.944 0h663.808c20.48 0 39.168 5.888 56.32 17.664 17.28 11.776 30.72 28.416 40.576 49.92 9.728 21.376 15.744 46.848 17.792 76.416 2.048 29.44-1.92 61.824-11.776 97.28l-28.16 110.848a381.696 381.696 0 0 1-98.688 172.032c-21.76 21.504-44.16 38.656-67.584 51.584-23.296 12.8-44.8 19.2-64.384 19.2H420.864l56.32-216.96h284.8l39.168-148.096zM1400.32 0l-98.432 360.192L1753.6 0h290.304L1480.96 463.104l309.632 461.44H1500.16L1243.136 575.744l-96 348.8H924.544L1177.984 0h222.464z"
              fill="#EE4416"
              p-id="7312"
            ></path>
          </svg>
          <div>对战</div>
        </div>
      </div>
      <div class="back">
        <div class="middle"></div>
        <img @click="back" src="../assets/back.png" alt="" />
      </div>
    </div>

    <div class="problem_list_his">
      <div class="list_con">
        <div v-for="(item, index) in prolist" :key="index" class="list_con_one">
          <div class="con_one_left" style="text-align: left">
            <h3>{{ item.name }}</h3>
          </div>
          <div
            style="
              display: flex;
              margin-top: 10px;
              font-size: 14px;
              color: #a49696;
              text-align: left;
            "
          >
            <div style="flex: 1">
              PK 得分:
              <span style="color: red"> {{ item.pkScore }}</span> &nbsp; 分
            </div>
            <div style="flex: 1; text-align: right">
              用时: {{ formatDuring(item.timeMs) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <load-more
      @refresh="onRefresh"
      :load-status="isLoadMore"
      :isShow="loadMoreShow"
    ></load-more>
  </div>
</template>

<script>
import globalData from "../api/globalData";
import { wxConPost } from "../api/httpApi";
import _ from "lodash";
import loadMore from "../widgets/loadMoreData.vue";
// import rnExamFooter from "../widgets/rnExamFooter.vue";

export default {
  name: "answerHisList",
  data() {
    return {
      proTitle: "",
      prolist: [],
      isLoadMore: true,
      loadMoreShow: false,
      activityId: "",
      curType: "双人PK",
    };
  },
  computed: {},
  components: { loadMore },
  methods: {
    getActivityTitle(type) {
      if (!type) {
        return "专项答题";
      } else {
        return type;
      }
    },
    // 回退
    back() {
      this.$router.push({ path: "/pkMain" });
    },
    // loadMore
    async onRefresh() {
      const query = {
        limit: 0,
        skip: this.prolist.length,
        id: this.activityId,
        type: this.curType,
      };
      try {
        const ret = await wxConPost(
          "/services/gttt-answer-exam/pk/pkList",
          query
        );

        if (ret.ret.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        } else {
          this.loadMoreShow = true;

          this.prolist = this.prolist.concat(ret.ret);
        }
      } catch (e) {
        this.loadMoreShow = true;
        this.isLoadMore = false;
      }
    },

    // 获取更多题目
    async getMore() {
      try {
        const ret = await wxConPost("/services/gttt-answer-exam/pk/pkList", {
          limit: 20,
          skip: 0,
          id: this.activityId,
          type: this.curType,
        });
        this.prolist = ret.ret;
        if (this.prolist.length <= 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    formatDuring(time) {
      let minutes = parseInt((time % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = (time % (1000 * 60)) / 1000;
      // 格式 00天 00时 00分 00秒
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes + "分/" + seconds + "秒";
    },
  },
  created() {
    this.activity = globalData.getActivity();

    if (!_.isEmpty(this.activity)) {
      // 取路由参数
      const query = this.$route.query;

      this.curType = query["type"];

      this.activityId = this.activity.id;
      this.getMore(this.proTitle);
    }
  },
};
</script>
